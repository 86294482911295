html, body, #root, .splash-screen {
  height: 100%;
  margin: 0;
  background-color: #F4F4F4;
  z-index: -1;
  background-color: #F4F4F4;
  position: relative;
}

#toast {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --deep-sky-blue: #0079ff;
  --black: #1f1f1f;
  --br-grey-light: #180a0a;
  --brownish-grey: #767676;
  --tomato: #ea4335;
  --white: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-underline-position: under;
  transition: 0.1s;
}

a:hover {
  color: #0079ff;
}
